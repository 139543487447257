import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface AppState {
  eyecatch: string;
  works: Work[];
}

export interface Work{
  id: number;
  slug: string;
  thumbnail: string;
  imageUrl: string;
  title: string;
}


@Module({ dynamic: true, store, name: 'appModule' })
class App extends VuexModule implements AppState {
  public eyecatch = '';
  public works = [] as Work[];

  @Action
  public setEyecatch(eyecatch: string) {
    this.SET_EYECATCH(eyecatch);
  }

  @Mutation
  private SET_EYECATCH(eyecatch: string) {
    this.eyecatch = eyecatch;
  }

  @Action
  public setWorks(works: Work[]) {
    this.SET_WORKS(works);
  }

  @Mutation
  private SET_WORKS(works: Work[]) {
    this.works = works;
  }
}
export const AppModule = getModule(App);
