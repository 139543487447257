import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Page from '../views/Page.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import('../views/Works.vue')
  },
  {
    path: '/works_:slug',
    name: 'WorksWithCategory',
    component: () => import('../views/Works.vue')
  },
  {
    path: '/tag/:id',
    name: 'WorksWithTag',
    component: () => import('../views/Works.vue')
  },
  {
    path: '/informations',
    name: 'Informations',
    component: () => import('../views/Informations.vue')
  },
  {
    path: '/informations/:slug',
    name: 'InformationsWithCategory',
    component: () => import('../views/Informations.vue')
  },
  {
    path: '/pages/:id',
    name: 'Pages',
    component: Page,
    meta: {
      type: 'pages'
    }
  },
  {
    path: '/posts/:id',
    name: 'Posts',
    component: Page,
    meta: {
      type: 'posts'
    }
  },
  {
    path: '/works/:slug',
    name: 'PostsWithSlug',
    component: Page,
    meta: {
      type: 'posts',
      postType: 'works',
    }
  },
  {
    path: '/info/:slug',
    name: 'InfoWithSlug',
    component: Page,
    meta: {
      type: 'posts',
      postType: 'informations',
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Page,
    meta: {
      type: 'page',
      slug: 'contact'
    }
  },
  {
    path: '/record',
    name: 'Record',
    component: Page,
    meta: {
      type: 'page',
      slug: 'record'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
