




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppModule, Work } from '@/store/app';
import Velocity from 'velocity-animate'

@Component
export default class WorksComponent extends Vue {
  private isVisible = true;
  private get works() {
    return AppModule.works;
  }

  private beforeEnter(el: HTMLElement) {
    el.style.opacity = '0';
    el.style.top = '15px'
  }

  private enter(el: any) {
    const delay = el.dataset.index * 200;
    setTimeout(function() {
      Velocity(
        el,
        { opacity: ['1', '0'], top: ['0px', '15px'] },
        {}
      )
    }, delay);
  }

  private beforeLeave(el: HTMLElement) {
    el.style.opacity = '1';
    el.style.top = '0px';
  }

  private leave(el: any, done: any) {
    Velocity(
      el,
      { opacity: ['0', '1'], top: ['15px', '0px'] },
      { duration: 250, complete: done }
    );
  }
}
