














































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class HeaderComponent extends Vue {
  private isWhite = false;
  private isOpen = false;
  created() {
    window.addEventListener('scroll', this.onScroll)
  }

  mounted(){
    this.onScroll();
  }

  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  }

  private onScroll(){
    if(this.$route.path=='/'){
      this.isWhite = this.$route.path=='/' && window.pageYOffset<window.innerHeight/2;
    }
  }

  @Watch('$route', {deep: true})
  private changeRoute(){
    if(this.$route.path=='/'){
      this.isWhite = this.$route.path=='/' && window.pageYOffset<window.innerHeight/2;
    }else{
      this.isWhite = false;
    }
    this.isOpen = false;
  }
}
