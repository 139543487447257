























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Work } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';
import WorksComponent from '@/components/WorksComponent.vue'
import { dateToFormatString } from '@/dateToFormatString'
import BaseButton from '@/components/BaseButton.vue'
@Component<Home>({
  components: {
    WorksComponent,
    BaseButton
  },
  head: {
    title() { return { inner: '' } },
    meta() {
      return [
        { property: 'og:url', content: 'https://www.iekichi.com/', id:'ogurl' },
        { property: 'og:title', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:image', content: this.eyecatch, id:'ogimage' },
        { property: 'og:description', content: '住まいをつくる、お店をつくる、農作物をつくる。様々な「つくる人たち」と、ゆるやかにつながり支えあいながら、建築を通して「心地よい暮らし」を提案しています。｜一級建築設計事務所 家吉建築デザイン', id: 'ogdescription'},
        { property: 'og:site_name', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id: 'ogsitename' },
        { name: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
      ];
    },
  }
})
export default class Home extends Vue {
  private informations = [];
  private abouts: any[] = [];

  private get eyecatch() {
    return AppModule.eyecatch;
  }

  private removeBr(str: string){
    return str.replace('<br>', ' ');
  }

  private members: any[] = [];
  private companyAbout = '';
  private companyAward = '';
  private companyDescription = '';
  private companyMedia = '';
  private loaded = false;
  private mounted(){
    ApiHelper.callApi(`pages/2?_embed`, 'get', {}, res => {
      AppModule.setEyecatch(res.data._embedded['wp:featuredmedia'][0].source_url);
      this.$emit('updateHead');
      this.companyAbout = res.data.acf.company_about;
      this.companyAward = res.data.acf.company_award;
      this.companyDescription = res.data.acf.company_description;
      this.companyMedia = res.data.acf.company_media;
      this.members = res.data.acf.members;
      this.abouts = res.data.acf.iekichi_details;
    });
    ApiHelper.callApi(`categories?slug=info`, 'get', {}, res => {
      ApiHelper.callApi(`posts?per_page=5&_embed&categories=${res.data[0].id}`, 'get', {}, res => {
        this.informations = res.data.map((v: any)=>{ return {...v, dateString: dateToFormatString(new Date(v.date),'%YYYY%年%M%月%D%日')} });
      });
    });

    ApiHelper.callApi(`categories?slug=works`, 'get', {}, res => {
      ApiHelper.callApi(`posts?per_page=4&_embed&categories=${res.data[0].id}`, 'get', {}, res => {
        AppModule.setWorks(res.data.map((v: any)=>{
          return {
            id: v.id,
            slug: v.slug,
            title: v.title.rendered,
            thumbnail: v._embedded['wp:featuredmedia'] ? v._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : undefined,
            imageUrl: v._embedded['wp:featuredmedia'] ? v._embedded['wp:featuredmedia'][0].source_url : undefined,
          } as Work;
        }));
        if(this.$route.hash){
          setTimeout(()=>{
            const el = document.querySelector(this.$route.hash);
            const rootRect = document.documentElement.getBoundingClientRect();
            window.scrollTo({top: (el as HTMLElement).getBoundingClientRect().top-rootRect.top-100});
            this.loaded = true;
          }, 1000);
        }
      });

    });

  }
}
