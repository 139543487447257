










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Work } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';
import inView from 'in-view'

import WorksComponent from '@/components/WorksComponent.vue'
import BaseButton from '@/components/BaseButton.vue'
import PopupComponent from '@/components/PopupComponent.vue'
@Component<Page>({
  components: {
    WorksComponent,
    BaseButton,
    PopupComponent
  },
  head: {
    title() { return { inner: this.title.replace('<br>', ' ') } },
    meta() {
      return [
        { property: 'og:url', content: this.meta["og:url"], id:'ogurl' },
        { property: 'og:title', content: this.meta["og:title"], id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:image', content: this.meta["og:image"], id:'ogimage' },
        { property: 'og:description', content: this.meta["og:description"], id: 'ogdescription'},
        { property: 'og:site_name', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id: 'ogsitename' },
        { name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
      ];
    },
  }
})
export default class Page extends Vue {
  private loaded = false;
  private subtitle = '';
  private place = '';
  private page: any = {};
  private images = [] as string[];
  private tags: any[] = [];
  private thumbnail = '';
  private meta = [] as any;

  private get title() {
    return this.page.title ? this.page.title.rendered : "";
  }

  mounted() {
    this.getData();
  }

  @Watch('$route')
  private routeChanged(to: any, from: any){
    if(to.fullPath.indexOf('#')>0)return;
    this.getData();
  }

  private getData(){
    this.loaded = false;
    let path = this.$route.meta.type;

    if(this.$route.meta.slug){
      path = `pages?slug=${this.$route.meta.slug}&_embed`;
    }else{
      if(this.$route.params.slug){
        path = `${path}/?slug=${this.$route.params.slug}&_embed`;
      }else{
        path = `${path}/${this.$route.params.id}?_embed`;
      }
    }
    ApiHelper.callApi(path, 'get', {}, res => {
      this.page = res.data[0] || res.data;
      this.loaded = true;
      this.subtitle = this.page.acf.work_subtitle;
      this.place = this.page.acf.work_place;
      this.tags = this.page._embedded['wp:term'] ? this.page._embedded['wp:term'][1] : null;
      this.thumbnail = this.page._embedded['wp:term'] ? this.page._embedded['wp:featuredmedia'][0].source_url : null;
      const yoast = res.data[0].yoast_meta;
      for (let i=0;i < yoast.length; i++) {
        this.meta[yoast[i].property] = yoast[i].content;
      }
      this.$emit('updateHead');
    });
  }

  updated(){
    this.runScript();
  }

  private popup(ev: any){
    const el = ev.target;
    if(el.dataset.fullUrl){
      this.images = Array.from(document.querySelectorAll('[data-full-url]')).map((e)=>{
        return (e as HTMLElement).dataset.fullUrl!;
      });
      (this.$refs.popup as PopupComponent).images = this.images;
      (this.$refs.popup as PopupComponent).popup(el.dataset.fullUrl);
    }
  }

  private runScript () {
    inView.offset(300);
    this.$el.querySelectorAll('img[loading]').forEach((el: Element) =>{
      if(inView.is(el))el.classList.add('loaded');
    });

    inView('[loading]').on('enter', (el: HTMLElement) =>{
      el.classList.add('loaded');
    })
    const scripts = this.$el.querySelectorAll('script')
    scripts.forEach(script => {
      const parentNode = script.parentNode;
      const alternativeNode = document.createElement('script');
      alternativeNode.src = script.src;
      parentNode!.replaceChild(alternativeNode, script);
    })
  }
}
