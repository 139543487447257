





























































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PopupComponent extends Vue {
  private isOpen = false;
  private image = '';
  private isVisible = true;
  private i = 0;
  public images!: string[];

  public popup(src: string){
    this.isOpen = true;
    this.i = this.images.indexOf(src);
    this.image = this.images[this.i];
  }

  private close(){
    this.isOpen = false;
  }

  private prev() {
    this.i--;
    if(this.i<0)this.i=0;
    this.image = this.images[this.i];
  }

  private next() {
    this.i++;
    if(this.i>this.images.length-1)this.i=this.images.length-1;
    this.image = this.images[this.i];
  }
}
