import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

import Inview from 'vueinview';
Inview.offset(100);
Vue.use(Inview);
Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-175193186-2'
});

import VueHead from 'vue-head'
Vue.use(VueHead, {
  separator: '',
  complement: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
