












import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
  }
})
export default class AppComponent extends Vue {
}
